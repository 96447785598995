import React, { useContext } from "react"
import { LanguageContext } from "../../contexts/languageContext"
import { getLocalizedField } from "../../utils/getLocalizedField"
import { translateText } from "../../utils/translateText"
import "./Testimonial.scss"
import parse from "html-react-parser"
const API_URL = process.env.GATSBY_API_URL

/**
 * Testimonial component.
 *
 * @param {Object} props
 * @param {Object} props.data Defines the data to display in the component.
 * @param {Object} props.data.image.formats Defines the format of the displayed image.
 * @param {Object} props.data.imageOrientation Defines the position of the displayed image.
 * @return {string} Testimonial component.
 */

const Testimonial = ({ data }) => {
  const {
    image,
    defaultImage,
    imageOrientation,
    name,
    body,
    position,
    company,
    city,
    country,
  } = data

  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const positionName =
    position && position[getLocalizedField("name", currentLanguage.label)]
  const companyName =
    company && company[getLocalizedField("name", currentLanguage.label)]
  const cityName =
    city && city[getLocalizedField("name", currentLanguage.label)]
  const countryName =
    country && country[getLocalizedField("name", currentLanguage.label)]

  let content = (
    <div className="user">
      <div className="wrapper-user-data">
        <div className="user__name">
          <h3 className="user__title">{name}</h3>
        </div>
        <div className="user__quotes">
          <p>{body && parse(body)}</p>
        </div>
      </div>
      <div className="user__header">
        <img
          className="user__img"
          src={`${image[0].staticImages.thumbnail[1].src}`}
          alt={image[0].alternativeText}
          width={image[0].formats.thumbnail[1].width}
          height={image[0].formats.thumbnail[1].height}
          position={image[0].positions}
          location={image[0].location}
        ></img>
      </div>
    </div>
  )

  if (imageOrientation === "left") {
    content = (
      <div className="user2">
        <div className="user__header2">
          <img
            className="user__img2"
            src={
              !defaultImage
                ? `${API_URL}${image[0].formats.thumbnail[1].url}`
                : image[0].formats.thumbnail[1].url
            }
            alt={image[0].alternativeText}
            width={image[0].formats.thumbnail[1].width}
            height={image[0].formats.thumbnail[1].height}
            location={image[0].location}
          />
          <div className="user__name2">
            <h3 className="user__title2">{name}</h3>
            <div className="user__occupation">
              <p className="user__position">{`${positionName}  ${translateText(
                "at",
                currentLanguage.fullTitle,
                googleSheetData
              )} ${companyName}`}</p>
              <p className="user__location">{`${cityName}, ${countryName}`}</p>
            </div>
          </div>
        </div>
        <div className="user__quotes2">
          <p>{body && parse(body)}</p>
          <span className="footer-title">
            {`${translateText(
              "Recruiting",
              currentLanguage.fullTitle,
              googleSheetData
            )}`}
          </span>
        </div>
      </div>
    )
  }

  return content
}

export default Testimonial
